import React, { useEffect, useMemo, useState } from 'react';

import UserAvatarButton from './user-center-content/user-avatar-button/user-avatar-button';
import UserCenterContent from './user-center-content/user-center-content';

import { DropdownMenu, useMediaQuery } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import type { ICurrencyItem } from 'components/header/header.types';
import BurgerMenuContainer from 'ui/burger-menu-container/burger-menu-container';

import { useGetUserInfo } from 'services/query-hooks/use-get-user-info';

import styles from './user-center.module.scss';

interface IUserCenterProps {
  avatar?: string;
}

export const defaultCurrency: ICurrencyItem = {
  currency: 'USD',
  icon: null,
  symbol: '$',
  type: 'fiat',
};

const UserCenter: React.FC<IUserCenterProps> = ({ avatar }) => {
  const isTablet = useMediaQuery('tablet');
  const isTabletS = useMediaQuery('tabletS');
  const { i18n } = useTranslation();

  const dir = useMemo(
    () => (['ar', 'fa'].includes(i18n.language) ? 'rtl' : 'ltr'),
    [i18n.language]
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [isTablet]);

  const { data: userInfo } = useGetUserInfo();

  return isTablet ? (
    <>
      <UserAvatarButton
        // image={avatar}
        login={userInfo?.data.result.email ?? undefined}
        size={isTabletS ? 'small' : 'medium'}
        onClick={() => setIsOpen(!isOpen)}
      />

      <BurgerMenuContainer
        align='right'
        direction={dir}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <UserCenterContent
          burger
          avatar={avatar}
          closeMenu={() => handleCloseMenu()}
        />
      </BurgerMenuContainer>
    </>
  ) : (
    <DropdownMenu
      className={styles.container}
      isOpen={isOpen}
      maxHeight={632}
      placement='bottom-end'
      setIsOpen={setIsOpen}
      width={336}
      parent={() => (
        <UserAvatarButton
          // image={avatar}
          login={userInfo?.data.result.email ?? undefined}
          size='medium'
          onClick={() => setIsOpen(!isOpen)}
        />
      )}
    >
      <UserCenterContent avatar={avatar} closeMenu={() => handleCloseMenu()} />
    </DropdownMenu>
  );
};

export default UserCenter;
