import React, { useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import LinkItem from '../../../user-center-content/link-item/link-item';

import { useThemeContext } from 'app/providers/theme-provider';
import clsx from 'clsx';
import { Switch, useMediaQuery } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import BurgerHeader from 'components/header/burger-menu/burger-header/burger-header';
import type {
  ISubItem,
  mainMenuItem,
} from 'components/header/navigation/nav-list/nav-item/nav-item.types';
import LanguageSelector from 'components/selectors/language-selector';
import DropdownList from 'ui/dropdown-list/dropdown-list';

import { getLanguageFullName } from 'utils/get-language-full-name';

import GlobeIcon from 'public/icons/header/globe.svg';
import MoonIcon from 'public/icons/header/moon.svg';

import styles from './settings-menu-content.module.scss';

interface ISettingsMenuContentProps {
  closeMenu: () => void;
  burger?: boolean;
}

const SettingsMenuContent: React.FC<ISettingsMenuContentProps> = ({
  closeMenu,
  burger = false,
}) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('main');

  const dir = useMemo(
    () => (['ar', 'fa'].includes(i18n.language) ? 'rtl' : 'ltr'),
    [i18n.language]
  );

  const [openSubMenu, setOpenSubMenu] = useState<ISubItem | null>(null);

  const { locale } = useRouter();
  const localeFullName = getLanguageFullName(locale);

  const { theme, toggleTheme } = useThemeContext();

  const isTabletS = useMediaQuery('tabletS');

  const useCenterContentMain: mainMenuItem[] = [
    {
      el: (
        <LinkItem
          icon={<GlobeIcon />}
          subTitle={localeFullName}
          title={t('layout.header.Language')}
        />
      ),
      subItem: {
        el: (
          <LanguageSelector
            inBurger={burger}
            maxHeight='576px'
            onChange={closeMenu}
          />
        ),
        title: t('layout.header.Language'),
      },
    },
    {
      el: (
        <LinkItem
          icon={<MoonIcon />}
          title={t('layout.header.Dark theme')}
          rightEl={
            <Switch
              aria-label={`Switch to ${theme === 'dark' ? 'light' : 'dark'} theme`}
              checked={theme === 'dark'}
              tabIndex={-1}
              onClick={toggleTheme}
            />
          }
          subTitle={
            theme === 'dark' ? t('layout.header.On') : t('layout.header.Off')
          }
          onClick={toggleTheme}
        />
      ),
    },
  ];

  const useCenterContent = useMemo(
    () =>
      burger
        ? [
            { el: <BurgerHeader reversed closeBurger={closeMenu} /> },
            ...useCenterContentMain,
          ]
        : useCenterContentMain,
    /* eslint-disable react-hooks/exhaustive-deps */
    [burger, useCenterContentMain, isTabletS]
  );

  return (
    <div className={clsx(styles.wrapper, { [styles.burger]: burger })}>
      <DropdownList
        burger={burger}
        direction={dir}
        mainLinks={useCenterContent}
        openSubMenuList={openSubMenu}
        setOpenSubMenuList={setOpenSubMenu}
      />
    </div>
  );
};

export default SettingsMenuContent;
