import { api } from './api';
import type { IBaseApiResponse } from './api.types';
import type { IServiceMethod } from './models/global.models';

export interface ICurrenciesListItem {
  commission_fee_amount: string;
  currency_code: string;
  currency_icon: string;
  min_amount: string;
  network_code: string;
  post_link: string | null;
}

export type TCurrenciesListResponse = ICurrenciesListItem[];

export interface ICurrency {
  currency: string;
  type: 'crypto' | 'fiat';
  icon?: string | null;
  networks?: INetwork[] | null;
  symbol?: string | null;
}

export interface INetwork {
  code: string;
  contract_address: string;
  name: string;
  token_standard?: string | null;
}

export interface IResponseErrors {
  [key: string]: any;
}

const getCurrencyListFiat: IServiceMethod<ICurrency[], { search?: string }> = (
  data
) => {
  const params: { type: string; search?: string } = { type: 'fiat' };

  if (data?.search) {
    params.search = data.search;
  }

  return api.get('v1/config/currency/list', {
    params,
  });
};

getCurrencyListFiat.queryKey = 'getCurrencyListFiat';

export const ConfigService = {
  async getCryptoCurrencyList() {
    return api
      .get<IBaseApiResponse<ICurrency[]>>('v1/config/currency/list?type=crypto')
      .then((data) => data.data.result);
  },

  async getCurrenciesList() {
    return api
      .get<IBaseApiResponse<TCurrenciesListResponse>>('v1/config/withdraws')
      .then((data) => data.data.result);
  },

  getCurrencyListFiat,
  async getGeo() {
    return api.get<{
      result: {
        geo: string;
      };
    }>('v1/fingerprint/geo');
  },
};
