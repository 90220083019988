import React, { useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import BurgerHeader from '../../../../../burger-menu/burger-header/burger-header';
import LinkItem from './link-item/link-item';
import UserHeader from './user-header/user-header';

import { useQueryClient } from '@tanstack/react-query';
import { useThemeContext } from 'app/providers/theme-provider';
import clsx from 'clsx';
import { Switch } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import {
  useCenterSubLinks,
  userCenterLinks,
} from 'components/header/header.data';
import type {
  ISubItem,
  mainMenuItem,
} from 'components/header/navigation/nav-list/nav-item/nav-item.types';
import FiatCurrencySelect from 'components/selectors/fiat-currency-select';
import LanguageSelector from 'components/selectors/language-selector';
import DropdownList from 'ui/dropdown-list/dropdown-list';

import { logout } from 'services/auth.service';
import { useGetUserInfo } from 'services/query-hooks/use-get-user-info';
import { UserService } from 'services/user.service';
import { getLanguageFullName } from 'utils/get-language-full-name';

import CurrencyIcon from 'public/icons/header/currency_dollar.svg';
import GlobeIcon from 'public/icons/header/globe.svg';
import LogoutIcon from 'public/icons/header/logOut.svg';
import MoonIcon from 'public/icons/header/moon.svg';
import UserIcon from 'public/icons/header/Profile.svg';
import IconSecure from 'public/icons/header/Shield.svg';

import styles from './user-center-content.module.scss';

interface IUserCenterContentProps {
  closeMenu: () => void;
  avatar?: string;
  burger?: boolean;
}

const UserCenterContent: React.FC<IUserCenterContentProps> = ({
  avatar,
  closeMenu,
  burger = false,
}) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('main');

  const dir = useMemo(
    () => (['ar', 'fa'].includes(i18n.language) ? 'rtl' : 'ltr'),
    [i18n.language]
  );

  const { locale } = useRouter();
  const localeFullName = getLanguageFullName(locale);
  const { theme, toggleTheme } = useThemeContext();

  const queryClient = useQueryClient();

  const { data: userInfo } = useGetUserInfo();

  const [openSubMenu, setOpenSubMenu] = useState<ISubItem | null>(null);

  const userInfoLogin = useMemo(() => {
    if (userInfo)
      return userInfo.data.result.email ?? (userInfo.data.result.phone || '');

    return '';
  }, [userInfo]);

  const handleLogOut = () => {
    closeMenu();
    logout();
  };

  const handleChangeCurrency = () => {
    queryClient.invalidateQueries({
      queryKey: [UserService.getUserInfo.queryKey],
    });
  };

  const useCenterContentMain: mainMenuItem[] = useMemo(
    () => [
      {
        el: (
          <UserHeader
            avatar={avatar}
            login={userInfoLogin}
            uid={userInfo?.data.result.uuid ?? ''}
          />
        ),
      },
      {
        el: <div className={styles.divider} />,
      },
      ...userCenterLinks.map((item) => ({
        el: (
          <LinkItem
            key={item.title}
            icon={item.icon}
            link={item.link}
            title={t('layout.header.' + item.title)}
            onClick={closeMenu}
          />
        ),
      })),
      {
        el: (
          <LinkItem
            withSub
            icon={<UserIcon />}
            title={t('layout.header.User Center')}
          />
        ),
        subItem: {
          el: (
            <div className={clsx(styles.subItem, { [styles.burger]: burger })}>
              {useCenterSubLinks.map((item) => (
                <LinkItem
                  key={item.title}
                  icon={item.icon}
                  link={item.link}
                  title={t('layout.header.' + item.title)}
                  // counter={item.title === 'Awards center' ? 12 : undefined}
                  onClick={closeMenu}
                />
              ))}
            </div>
          ),
          title: t('layout.header.User Center'),
        },
      },
      {
        el: <div className={styles.divider} />,
      },
      {
        el: (
          <LinkItem
            icon={<GlobeIcon />}
            subTitle={localeFullName}
            title={t('layout.header.Language')}
          />
        ),
        subItem: {
          el: (
            <LanguageSelector
              inBurger={burger}
              maxHeight='576px'
              onChange={closeMenu}
            />
          ),
          title: t('layout.header.Language'),
        },
      },
      {
        el: (
          <LinkItem
            icon={<CurrencyIcon />}
            // eslint-disable-next-line max-len
            subTitle={userInfo?.data.result.equivalent_currency_code.toUpperCase()}
            title={t('layout.header.Currency')}
          />
        ),
        subItem: {
          el: (
            <FiatCurrencySelect
              closeSubMenu={() => setOpenSubMenu(null)}
              inBurger={burger}
              maxHeight='576px'
              onChange={() => handleChangeCurrency()}
            />
          ),
          title: t('layout.header.Currency'),
        },
      },
      {
        el: (
          <LinkItem
            icon={<MoonIcon />}
            title={t('layout.header.Dark theme')}
            rightEl={
              <Switch
                aria-label={`Switch to ${theme === 'dark' ? 'light' : 'dark'} theme`}
                checked={theme === 'dark'}
                tabIndex={-1}
                onClick={toggleTheme}
              />
            }
            subTitle={
              theme === 'dark' ? t('layout.header.On') : t('layout.header.Off')
            }
            onClick={toggleTheme}
          />
        ),
      },
      {
        el: <div className={styles.divider} />,
      },
      {
        el: (
          <LinkItem icon={<IconSecure />} title={t('layout.header.Account')} />
        ),
        subItem: {
          el: (
            <div className={clsx(styles.subItem, { [styles.burger]: burger })}>
              {/* <LinkItem title={t('Switch account')} icon={<SwitchIcon />} onClick={closeMenu} /> */}
              <LinkItem
                icon={<LogoutIcon />}
                title={t('layout.header.Log out')}
                onClick={handleLogOut}
              />
            </div>
          ),
          title: t('layout.header.Account'),
        },
      },
    ],
    /* eslint-disable react-hooks/exhaustive-deps */
    [burger, avatar, closeMenu, userInfoLogin, theme]
  );

  const centerContent = useMemo(
    () =>
      burger
        ? [
            { el: <BurgerHeader reversed closeBurger={closeMenu} /> },
            ...useCenterContentMain,
          ]
        : useCenterContentMain,
    [burger, closeMenu, useCenterContentMain]
  );

  return (
    <div className={clsx(styles.wrapper, { [styles.burger]: burger })}>
      <DropdownList
        burger={burger}
        direction={dir}
        mainLinks={centerContent}
        openSubMenuList={openSubMenu}
        setOpenSubMenuList={setOpenSubMenu}
      />
    </div>
  );
};

export default UserCenterContent;
