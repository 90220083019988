'use client';

import React, { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import NotificationCenterHeader from './notification-center-header/notification-center-header';
import NotificationItem from './notification-item/notification-item';
import NotificationSkeletons from './notification-skeletons/notification-skeletons';
import NotificationsEmpty from './notifications-empty/notifications-empty';

import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import clsx from 'clsx';
import { AnimatePresence } from 'framer-motion';

import { UserService } from 'services/user.service';
import { useInView } from 'hooks/use-in-view';

import SpinIcon from 'public/icons/header/Loader3.svg';

import styles from './notification-center-content.module.scss';

interface INotificationCenterProps {
  closeMenu?: () => void;
  unread_notifications?: number;
}

const NotificationCenterContent: React.FC<INotificationCenterProps> = ({
  unread_notifications,
  closeMenu,
}) => {
  // const [selectedItems, setSelectedItems] = useState<string[]>([]);
  // const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  const { locale } = useRouter();

  // QUERY [start]

  const queryClient = useQueryClient();

  const { mutate: handleReadAll } = useMutation({
    mutationFn: () => UserService.readAllNotifications(),
    mutationKey: [UserService.readAllNotifications.queryKey],
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [UserService.getNotificationsUnread.queryKey],
      });
    },
  });

  const {
    data: notificationsList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    // eslint-disable-next-line unicorn/no-abusive-eslint-disable, @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    getNextPageParam: (lastPage) => lastPage.data.result.paginate.nextCursor,

    initialPageParam: 1,

    queryFn: ({ pageParam = 1 }) =>
      UserService.getNotifications({
        cursor: pageParam,
      }),

    queryKey: [UserService.getNotifications.queryKey],
    // eslint-disable-next-line unicorn/no-abusive-eslint-disable, @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    select: (data) => data.pages.flatMap((page) => page.data.result.items),
  });

  //QUERY [end]

  useEffect(() => {
    refetch();
  }, [locale, refetch]);

  const inViewCallback: IntersectionObserverCallback = useCallback(
    async ([entry]) => {
      if (entry.isIntersecting && hasNextPage && !isFetchingNextPage) {
        await fetchNextPage();
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [notificationsList]
  );

  const callbackRef = useInView(inViewCallback);

  //TODO ждем бэк

  // const handleSelectItem = (value: string) => {
  //   if (selectedItems.includes(value)) {
  //     setSelectedItems((prev) => prev.filter((item) => item !== value));
  //     setIsSelectAll(false);
  //   } else setSelectedItems((prev) => [...prev, value]);
  // };

  // const handleSelectAll = () => {
  //   setIsSelectAll(true);
  //   setSelectedItems(notificationsList?.map((item) => item.uuid));
  // };

  // const handleCancelSelected = () => {
  //   setIsSelectAll(false);
  //   setSelectedItems([]);
  // };

  // const handleDeleteSelected = () => {
  //   //TODO QUERY
  //   if (isSelectAll) {
  //     setSelectedItems([]);
  //     // setNotificationsList([]);
  //     setBlockFetching(true);
  //     setIsSelectAll(false);
  //   } else {
  //     // setNotificationsList((prev) => prev.filter((item) => !selectedItems.includes(item.uuid)));
  //     setSelectedItems([]);
  //   }
  // };

  // const handleReadSelected = () => {
  //   //TODO QUERY
  //   if (isSelectAll) {
  //     handleReadAll();
  //   } else console.log('readed : ', selectedItems);
  // };

  // console.log('test', notificationsList);

  return (
    <div className={styles.container}>
      <NotificationCenterHeader
        // selectedCount={!isSelectAll ? selectedItems?.length : notificationsListDataMOCK.total}
        // isSelectAll={isSelectAll}
        closeMenu={closeMenu}
        isLoading={isLoading}
        nothingFound={!isFetching && !notificationsList?.length}
        readAll={handleReadAll}
        unreadCount={unread_notifications}
      />
      <div
        className={clsx(styles.notifications_list, {
          // [styles.some_selected]: selectedItems.length > 0,
        })}
      >
        {isLoading && <NotificationSkeletons />}
        {!isLoading && notificationsList?.length
          ? notificationsList.map((item) => (
              <NotificationItem
                key={item.uuid}
                {...item}
                // selected={selectedItems.includes(item.uuid)}
              />
            ))
          : null}
        {!isLoading && !notificationsList?.length && <NotificationsEmpty />}
        {/* TODO FROM QUERY */}
        <AnimatePresence mode='wait'>
          {isFetchingNextPage ? (
            <div className={styles.list__loading}>
              <SpinIcon />
            </div>
          ) : null}
        </AnimatePresence>

        {!isLoading && !isFetchingNextPage ? (
          <div
            ref={isFetchingNextPage ? null : callbackRef}
            className={styles.notifications_list__bottom}
          ></div>
        ) : null}
      </div>
      {/* <BottomMenu
        // readSelected={handleReadSelected}
        // deleteSelected={handleDeleteSelected}
        isSelectAll={isSelectAll}
        isReadAll={unread_notifications === 0}
        visible={selectedItems?.length > 0}
      /> */}
    </div>
  );
};

export default NotificationCenterContent;
